
<template>
<div style="margin-top: 100px;">
    <BasePageBreadCrumb :pages="pages" title="novo cliente" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <b-tabs>
                                <form class="px-2 needs-validation" novalidate @submit.prevent="save">

                                <b-tab class="tab-pane" id="dadospessoais">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Dados</span>
                                        </div>
                                    </template>

                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Código QS</label>
                                                    <input type="text" class="form-control" placeholder="#5898" disabled v-model="id">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Cliente desde </label>
                                                    <input type="date" class="form-control" placeholder="21/01/2021" name="client_since_date" v-model="client.client_since_date" v-validate="'required'" :class="{'is-invalid': errors.has('client_since_date')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Data cadastro </label>
                                                    <input type="date" class="form-control" placeholder="21/01/2021" disabled v-model="client.created_at">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Como conheceu a empresa? </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.source" name="source" :class="{'is-invalid': errors.has('source')}">
                                                                <option :value="null">Selecione</option>
                                                                <option value="GOOGLE">Google</option>
                                                                <option value="SOCIAL_MEDIA">Rede Social</option>
                                                                <option value="RADIO">Rádio</option>
                                                                <option value="OUTDOOR">Outdoor</option>
                                                                <option value="MAGAZINE">Revista</option>
                                                                <option value="INDICATION">Indicação</option>
                                                                <option value="OTHER">Outros</option>
                                                            </select>
                                                </div>
                                                <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Definir a origem do lead</small>
                                            </div>
                                            <div class="col-12 pt-4"></div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Nome fantasia </label>
                                                    <input type="text" class="form-control" v-model="client.fantasy_name" v-validate="'required'" name="fantasy_name" :class="{'is-invalid': errors.has('fantasy_name')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Razão social </label>
                                                    <input type="text" class="form-control" v-model="client.social_name" v-validate="'required'" name="social_name" :class="{'is-invalid': errors.has('social_name')}">
                                                </div>
                                            </div>
                                            <div class="col-12"></div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>CNPJ </label>
                                                    <input type="tel" class="form-control" v-model="client.cnpj" v-validate="'required'" name="cnpj" :class="{'is-invalid': errors.has('cnpj')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>Inscrição estadual </label>
                                                    <input type="text" class="form-control" v-model="client.insc_state" v-validate="'required'" name="insc_state" :class="{'is-invalid': errors.has('insc_state')}" v-mask="'#################'">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>Inscrição municipal </label>
                                                    <input type="text" class="form-control" v-model="client.insc_city" v-validate="'required'" name="insc_city" :class="{'is-invalid': errors.has('insc_city')}" v-mask="'##############'">
                                                </div>
                                            </div>
                                            <div class="col-12"></div>
                                            <br>
                                            <div class="col-12 py-2">
                                                <h4 class="card-title">Usuário de acesso </h4>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Nome </label>
                                                    <input type="text" class="form-control" v-model="client.first_name" v-validate="'required'" name="first_name" :class="{'is-invalid': errors.has('first_name')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Sobrenome </label>
                                                    <input type="text" class="form-control" v-model="client.last_name" v-validate="'required'" name="last_name" :class="{'is-invalid': errors.has('last_name')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>CPF </label>
                                                    <input type="text" class="form-control" v-model="client.cpf" v-validate="'required'" name="cpf" :class="{'is-invalid': errors.has('cpf')}" v-mask="'###.###.###-##'">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Sexo </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.gender" v-validate="'required'" name="gender" :class="{'is-invalid': errors.has('gender')}">
                                                        <option :value="null">Selecione</option>
                                                        <option value="MALE">Masculino</option>
                                                        <option value="FEMALE">Feminino</option>
                                                        <option value="NONE">Não quero informar</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Data de nascimento </label>
                                                    <input type="date" class="form-control" v-model="client.birth_date"  name="birth_date" >
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>E-mail </label>
                                                    <input type="email" class="form-control" placeholder="abc@example.com" v-model="client.email" v-validate="'required'" name="email" :class="{'is-invalid': errors.has('email')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Telefone </label>
                                                    <input type="tel" class="form-control" placeholder="(44) 4444-4445" v-model="client.telephone" v-validate="'required'" name="telephone" :class="{'is-invalid': errors.has('telephone')}" v-mask="'(##) ####-####'">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Whatsapp </label>
                                                    <input type="tel" class="form-control" placeholder="(44) 4444-4445" v-model="client.cellphone" v-validate="'required'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}" v-mask="'(##) #####-####'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab class="tab-pane" id="enderecoresidencial">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-map font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Endereço comercial <b-badge variant="danger" v-show="address_error">1</b-badge></span>
                                        </div>
                                    </template>
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-4 col-lg-3">
                                                    <div class="form-group">
                                                        <label>CEP </label>
                                                        <input type="tel" class="form-control" @blur="getAddress()" placeholder="74.830-500" v-model="client.cep" name="cep" v-validate="'required'" :class="{'is-invalid': errors.has('cep')}" v-mask="'##.###-###'">
                                                    </div>
                                                </div>
                                                <div class="col-12"></div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Rua/Avenida/Alameda/Travessa </label>
                                                        <input type="text" class="form-control" placeholder="" v-model="client.street" v-validate="'required'" name="street" :class="{'is-invalid': errors.has('street')}">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="form-group">
                                                        <label>Número </label>
                                                        <input type="text" class="form-control" placeholder="" v-model="client.number" v-validate="'required'" name="number" :class="{'is-invalid': errors.has('number')}">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Complemento </label>
                                                        <input type="text" class="form-control" placeholder="" v-model="client.complement" name="complement" v-validate="'required'" :class="{'is-invalid': errors.has('complement')}">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Bairro </label>
                                                        <input type="text" class="form-control" placeholder="" v-model="client.neighborhood" name="neighborhood" v-validate="'required'" :class="{'is-invalid': errors.has('neighborhood')}">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="form-group">
                                                        <label>UF </label>
                                                        <select class="custom-select mr-sm-2" v-model="client.state" v-validate="'required'" name="uf" :class="{'is-invalid': errors.has('uf')}">
                                                            <option :value="null">Selecione</option>
                                                            <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Município </label>
                                                        <select class="custom-select mr-sm-2" v-model="client.city" v-validate="'required'" name="city" :class="{'is-invalid': errors.has('city')}">
                                                            <option :value="null">Selecione</option>
                                                            <option v-for="item in getCities(client.state)" :key="item" :value="item">{{ item }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="form-group">
                                                        <label>Sua residência é </label>
                                                        <select class="custom-select mr-sm-2" v-model="client.residence_type" name="residence_type" v-validate="'required'" :class="{'is-invalid': errors.has('residence_type')}">
                                                            <option :value="null">Selecione</option>
                                                            <option value="LEASED">Alugada</option>
                                                            <option value="PARENTS">Mora com parentes/pais</option>
                                                            <option value="GIVEN">Cedida/Funcional</option>
                                                            <option value="OWN">Própria quitada</option>
                                                            <option value="FINANCED_CAIXA">Financiada pela CAIXA</option>
                                                            <option value="FINANCED">Financiada por outras entidades</option>
                                                            <option value="OTHERS">Outros</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </b-tab>
                                  <b-tab class="tab-pane" id="configuracoes">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Configurações <b-badge variant="danger" v-show="basic_error">1</b-badge></span>
                                        </div>
                                    </template>
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Status do cadastro </label>
                                                        <select class="custom-select mr-sm-2" v-model="client.is_active">
                                                            <option :value="true">Ativo</option>
                                                            <option :value="false">Inativo</option>
                                                        </select>
                                                    </div>
                                                    <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do cliente na plataforma</small>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Tema de personalização de apartamento</label>
                                                            <select class="custom-select mr-sm-2" v-model="client.apto_personalization_theme">
                                                                <option value="tema1">Tema 1.0</option>
                                                                <option value="tema2">Tema 2.0</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do cliente na plataforma</small>
                                                    </div>

                                                <div class="col-12 col-md-12 col-lg-12">
                                                
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 col-lg-6 form-group">
                                                            <label>Domínio principal</label>
                                                            <input type="url" class="form-control" id="url" placeholder="https://domain.example.com" v-model="client.domain_master">
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                
                                               
                                                <div class="col-12 pt-4 pb-2">
                                                    <h4 class="card-title">Permissões de Módulos</h4>
                                                </div>

                                                <div class="col-12 p-0">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div v-for="permission in permissions" :key="permission.id" class="col-12 col-md-4 col-lg-3 text-truncate p-1">
                                                                <div  class="custom-control custom-checkbox">
                                                                    <input :checked="permissions_selecteds[permission.id]" type="checkbox" @click="changePermissionsSelected(permission.id)"  class="custom-control-input" :id="permission.id">
                                                                    <label class="custom-control-label" :for="permission.id">{{permission.name}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 pt-4 pb-2">
                                                    <h4 class="card-title">Senha de acesso</h4>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Resetar senha </label>
                                                        <input type="password" class="form-control" id="passtext" placeholder="********" v-model="client.password" v-validate="passwordValidate" ref="password" name="password" :class="{'is-invalid': errors.has('password')}">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Confirmar senha </label>
                                                        <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="confirmPasswordValidate" name="confirm_password" :class="{'is-invalid': errors.has('confirm_password')}">
                                                    </div>
                                                    <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                                </div>
                                            </div>
                                        </div>
                                  </b-tab>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn btn-info ml-2">{{ id ? 'Salvar' : 'Cadastrar'}}</button>
                                    </div>
                                </div>
                                </form>
                            </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>
<script>
import AdminService from "@/services/resources/AdminService";
import PermissionService from "@/services/resources/PermissionService";
import states from '@/assets/js/states.json';
import axios from 'axios';

const service = AdminService.build();
const servicePermission = PermissionService.build();

export default {
    data(){
        return{
            client:{
                type: 'ADMIN',
                client_since_date: '',
                source: null,
                first_name: null,
                last_name: null,
                cpf: null,
                fantasy_name: null,
                social_name: null,
                cnpj: null,
                insc_state: null,
                insc_city: null,
                birth_date: null,
                gender: null,
                marital_status: null,
                email: null,
                apto_personalization_theme: 'tema1',
                telephone: null,
                cellphone: null,
                cep: null,
                street: null,
                number: null,
                complement: null,
                neighborhood: null,
                city: null,
                state: null,
                residence_type: null,
                is_active: null,
                password: null,
                is_active: true,
                company_id: null,
                permissions_company: [],
                is_master: true,
                domain_master: ''
            },
            id: null,
            search: null,
            states: [],
            companies: [
                { value: null, text: 'Selecione' }
            ],
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'novo cliente',
                    to: 'Client'
                }
            ],
            menu:[
                {
                    name: 'Novo cliente',
                    to: 'ClientNew'
                },
                {
                    name: 'Clientes',
                    to: 'Client'
                }
            ],
            basic_error: false,
            address_error: false,
            permissions: [],
            permissions_selecteds: {},
            
        }
    },
    computed:{
       passwordValidate(){
           return this.id ? 'min:8' : 'required|min:8';
       },
       confirmPasswordValidate(){
           return this.id ? 'confirmed:password' : 'required|confirmed:password';
       }
   },
    methods:{
    changePermissionsSelected(id) {
        this.permissions_selecteds[id] = !this.permissions_selecteds[id];
    },
     getAddress(){
          
          if(this.client.cep.length === 10) {
            let url = `https://viacep.com.br/ws/${this.client.cep.replace('-', '').replace('.', '')}/json`;

                axios.get(url).then(resp =>{
                    this.client.street = resp.data.logradouro;
                    this.client.neighborhood = resp.data.bairro;
                    this.client.state = resp.data.uf;
                    this.client.city = resp.data.localidade;
                });
          }

   
       },
        getCities(uf){
           
           if(!uf)
                return []

           let state = this.states.filter(elem => {
               return elem.sigla === uf;
           });
           if(!state.length)
                return state;
                
           return state[0].cidades;

       },
       validateBasicTab(){
           if(!this.client.password && !this.id){
               this.basic_error = true;
               return false;
           }

           return true;
       },
       validateAddressTab(){
            if(!this.client.cep || !this.client.street || !this.client.number || !this.client.complement || !this.client.neighborhood || !this.client.city || !this.client.state || !this.client.residence_type){
                this.address_error = true;
                return false;
            }

           return true;
       },
        save(){

            this.basic_error = false;
            this.address_error = false;
            let permissionsSelected = [];

        //     this.client.company.themes.forEach((re) => {
        //         if (re.key == "SETTINGS_APTO_PERSONALIZATION_THEME") {
        //              this.client.company.themes[this.client.company.themes.length] = {                  
        //                  key: 'SETTINGS_APTO_PERSONALIZATION_THEME',
        //                  value: this.$client.company.themes.apto_personalization_theme
        //             };
        //         }
        //     }) 
           

        //    let aptoThemeSetting =  this.client.company.themes.filter((re) => {
        //         if (re.key.SETTINGS_APTO_PERSONALIZATION) {
        //             return re
        //         }
        //     })  
            
            // if (aptoThemeSetting.length < 1) {   
            //      this.client.company.themes[this.client.company.themes.length] = {                  
            //          key: 'SETTINGS_APTO_PERSONALIZATION_THEME',
            //          value: this.$data.client.apto_personalization_theme
            //     }              
            // }
            
     

           Object.keys(this.permissions_selecteds).forEach(permission => {

                if(this.permissions_selecteds[permission]) {
                    permissionsSelected.push({
                        id: permission
                    });
                }
           });

            this.$validator.validateAll().then((result) => {
            if (result & this.validateBasicTab() & this.validateAddressTab()) {

                this.client.theme = []
          
                if(this.client.domain_master.length) {
                    this.client.theme[0] = {
                            key: 'SETTINGS_DOMAIN_MASTER',
                            value: this.client.domain_master
                        };
                }

                if(this.client.apto_personalization_theme.length) {
                    this.client.theme[this.client.theme.length] = {
                             key: 'SETTINGS_APTO_PERSONALIZATION_THEME',
                            value: this.client.apto_personalization_theme       
                    }
                }


           
                
                let client = Object.assign(this.client);
         
    
                client.permissions_company = permissionsSelected;

                if(this.id){
                    client.id = this.id;

                    service
                    .update(client)
                    .then(resp => {
                    this.$bvToast.toast('Seu cliente foi atualizado com sucesso!', {
                        title: 'Cliente atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do cliente',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }else{
                    service
                    .create(client)
                    .then(resp => {
                    this.$bvToast.toast('Seu cliente foi criado com sucesso!', {
                        title: 'Cliente criado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do cliente',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }
          
            }
        });
       },
        async fetchClient(){

            const permissions = await servicePermission.get('');

            this.permissions = permissions;

            console.log(this.permissions);

            if(!this.id){
                return;
            }

            let data = {
                id: this.id
            }

            const resp = await service.read(data);

            let domain_master = '';
            
            let apto_personalization_theme = 'tema1';
            if (resp.company.themes != null) {
                resp.company.themes.forEach(item => {
                    console.log(item);
                    if(item.key === 'SETTINGS_DOMAIN_MASTER') domain_master = item.value;
                    if(item.key === 'SETTINGS_APTO_PERSONALIZATION_THEME') apto_personalization_theme = item.value;
                    console.log(item.key);
                });            
            }



                this.client.fantasy_name = resp.fantasy_name;
                this.client.social_name = resp.social_name;
                this.client.first_name= resp.first_name;
                this.client.last_name = resp.last_name;
                this.client.cellphone = resp.cellphone
                this.client.cnpj = resp.cnpj;
                this.client.created_at = resp.created_at    
                this.client.street = resp.client.street;
                this.client.number = resp.client.number;
                this.client.gender = resp.client.gender
                this.client.cep = resp.client.cep       
                this.client.neighborhood = resp.client.neighborhood
                this.client.complement= resp.client.complement;
                this.client.city = resp.client.city
                this.client.state = resp.client.state
                this.client.residence_type = resp.client.residence_type
                this.client.insc_city = resp.insc_city;
                this.client.insc_state = resp.insc_state
                this.client.cpf = resp.cpf;
                this.client.password = null;
                this.client.cellphone = resp.cellphone;
                this.client.telephone = resp.client.telephone;
                this.client.email = resp.email;
                this.client.apto_personalization_theme = apto_personalization_theme,
                this.client.is_active = this.client.is_active == 1;
                this.client.company = resp.company;
                this.client.domain_master = domain_master;
                this.client.client_since_date = resp.created_at.split('T')[0];
                this.client.birth_date = resp.birth_date;

                permissions.forEach(permission => {
                    this.permissions_selecteds[permission.id] = false;        
                })

                resp.company.permissions.forEach(permission => {


                    this.permissions_selecteds[permission.permission_id] = true;

                });

                this.$validator.reset();

       },
   
       
    },
    mounted(){
        this.states = states;
        this.id = this.$route.params.id;
        this.fetchClient();
    }
}
</script>